import React, { useState } from 'react';
import { StyleSheet, css } from 'aphrodite';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, TextField, Typography } from '@mui/material';
import Calendar from './Calendar';
import History from './History';
import { Category, sync, useCategory } from '../data/Data';
import EditDialog from './EditDialog';

type Props = {
    category: Category,
};

export default function CategoryView(props: Props) {
    const cat = useCategory(props.category);
    const [open, setOpen] = useState(false);
    const [name, setName] = useState('');
    const [offset, setOffset] = useState(0);
    const today = new Date();
    today.setDate(today.getDate() + offset);

    const onCreate = () => {
        cat.events.push({
            name: name.trim() === '' ? null : name,
            date: today.getTime(),
        });

        sync();

        setOpen(false);
        setName('');
        setOffset(0);
    };

    return (
        <div className={css(styles.page)}>
            <div className={css(styles.content)}>
                <Typography variant="h5">
                    {cat.name}
                <EditDialog category={cat} />
                </Typography>
                <Calendar category={cat} />
                <History category={cat} />
            </div>
            <div className={css(styles.fab)}>
                <Fab color="primary" aria-label="add" onClick={() => setOpen(true)}>
                    <AddIcon />
                </Fab>
            </div>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>
                    New Event
                </DialogTitle>
                <DialogContent>
                    <div style={{height: 5}} />
                    <TextField
                            id="name"
                            label="Optional Name"
                            fullWidth
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    <Typography>
                        {today.toDateString()}
                    </Typography>
                    <ButtonGroup variant="outlined">
                        <Button onClick={() => setOffset(o => o -1)}>
                            {'<'}
                        </Button>
                        <Button onClick={() => setOffset(o => o +1)}>
                            {'>'}
                        </Button>
                    </ButtonGroup>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>Cancel</Button>
                    <Button onClick={onCreate}>Create</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const styles = StyleSheet.create({
    page: {
        position: 'relative',
        height: '100%',
        width: '100%',
    },
    content: {
        margin: 16,
    },
    fab: {
        position: 'absolute',
        bottom: 30,
        right: 30,
    },
});
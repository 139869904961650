import { Card, IconButton, Typography } from '@mui/material';
import { StyleSheet, css } from 'aphrodite';
import React, { useCallback, useMemo } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { Category, Event, sync, useCategory } from '../data/Data';

type Props = {
    category: Category,
};
export default function History(props: Props) {
    const cat = useCategory(props.category);
    const ordered = cat.events.sort((l, r) => r.date - l.date);

    return (
        <div>
            {ordered.map(e => <EventCard category={cat} event={e} />)}
        </div>
    );
}

type EventProps = {
    event: Event,
    category: Category,
};
function EventCard(props: EventProps) {
    const date = useMemo(() => new Date(props.event.date), [props.event.date]);

    const onDelete = () => {
        const index = props.category.events.findIndex(e => e.date === props.event.date);

        if (index !== -1) {
            props.category.events.splice(index, 1);
            sync();
        }
    };

    return (
        <Card variant="outlined" className={css(styles.event)}>
            <div>
                <Typography variant="caption">
                {date.toDateString()} - {date.toLocaleTimeString()}
                </Typography>
                <Typography>
                    {props.event.name ?? 'Instance'}
                </Typography>
            </div>
            <IconButton>
                <DeleteIcon onClick={onDelete} />
            </IconButton>
        </Card>
    );
}

const styles = StyleSheet.create({
    event: {
        marginTop: 16,
        marginBottom: 16,
        padding: 16,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    }
});
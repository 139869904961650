import React from 'react';
import { StyleSheet, css } from 'aphrodite';

type Props = {
    data: Array<React.ReactNode>,
};

export default function Carousel(props: Props) {
    const renderChild = (child: React.ReactNode, index: number) => {
        return (
            <div className={css(styles.carouselItem)}>
                {child}
            </div>
        );
    };

    return (
        <div className={css(styles.carousel)}>
            {props.data.map(renderChild)}
        </div>
    );
}

const styles = StyleSheet.create({
    carousel: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        overflow: 'scroll',
        scrollSnapType: 'x mandatory'
    },
    carouselItem: {
        flexShrink: 0,
        width: '100%',
        height: '100%',
        scrollSnapAlign: 'start',
        // backgroundColor: 'grey',
    },
});
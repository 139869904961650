import React from 'react';
import AppBar from './AppBar';
import Carousel from './Carousel';
import { StyleSheet, css } from 'aphrodite';
import NewCategoryPage from './NewCategoryPage';
import CategoryView from './CategoryView';
import { useCategories } from '../data/Data';

export default function RootView() {
    const categories = useCategories();

    return (
        <div className={css(styles.root)}>
            <AppBar />
            <Carousel data={[
                ...categories.map(c => <CategoryView category={c} />),
                <NewCategoryPage />
            ]} />
        </div>
    );
}

const styles = StyleSheet.create({
    root: {
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
    },
});
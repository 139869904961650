import React from 'react';
import { StyleSheet, css } from 'aphrodite';

export default function AppBar() {
    return (
        <div className={css(styles.appbar)}>
            Forgetti
        </div>
    );
}

const styles = StyleSheet.create({
    appbar: {
        height: 60,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: 'Plathon',
        fontSize: 60,
    },
});
import EventEmitter from "events";
import { useEffect, useState } from "react";

export type Category = {
    name: string,
    events: Array<Event>,
};

export type Event = {
    name: string | null,
    date: number,
};

export let Categories: Array<Category> = [];
const changes = new EventEmitter();

export function loadData() {
    Categories = JSON.parse(localStorage.getItem('CATEGORIES') ?? '[]');
}

export function sync() {
    changes.emit('change');
    localStorage.setItem('CATEGORIES', JSON.stringify(Categories));
}

export function useCategories(): Array<Category> {
    const [_, reRender] = useState({});

    useEffect(() => {
        const listener = () => reRender({});
        changes.addListener('change', listener);
        return () => {
            changes.removeListener('change', listener);
        };
    }, []);

    return Categories;
}

export function useCategory(cat: Category): Category {
    const [_, reRender] = useState({});

    useEffect(() => {
        const listener = () => reRender({});
        changes.addListener('change', listener);
        return () => {
            changes.removeListener('change', listener);
        };
    }, []);

    return cat;
}

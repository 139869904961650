import React, { useEffect } from 'react';
import RootView from './components/RootView';
import { loadData } from './data/Data';

loadData();

function App() {
  return (
    <div className="App">
      <RootView />
    </div>
  );
}

export default App;

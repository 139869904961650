import React, { useState } from 'react';
import { StyleSheet, css } from 'aphrodite';
import AddIcon from '@mui/icons-material/Add';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Typography } from '@mui/material';
import { Categories, sync } from '../data/Data';


export default function NewCategoryPage() {
    const [open, setOpen] = useState(false);
    const [name, setName] = useState('');

    const onCreate = () => {
        if (name.trim() !== '') {
            Categories.push({
                name,
                events: [],
            });
            sync();
            setOpen(false);
            setName('');
        }
    };

    return (
        <div className={css(styles.container)}>
            <Button variant="outlined" endIcon={<AddIcon fontSize="inherit"/>}
                onClick={() => setOpen(true)}
            >New Category</Button>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>
                    New Category
                </DialogTitle>
                <DialogContent>
                    <div style={{height: 5}} />
                    <TextField
                            autoFocus
                            id="name"
                            label="Name"
                            fullWidth
                            onChange={(e) => setName(e.target.value)}
                        />

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>Cancel</Button>
                    <Button onClick={onCreate}>Create</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
    },
});
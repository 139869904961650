import { Badge, Button, ButtonGroup, IconButton, Typography } from '@mui/material';
import { StyleSheet, css } from 'aphrodite';
import React, { useCallback, useMemo, useState } from 'react';
import { Category, useCategory } from '../data/Data';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';

type MonthProps = {
    month: number,
    year: number,
    category: Category,
}

function usa2world(dayNum: number): number {
    if (dayNum === 0) {
        // Sun
        return 6;
    }
    return dayNum - 1;
}

function Month(props: MonthProps) {
    const firstDate = useMemo(() => new Date(props.year, props.month, 1), [props.year, props.month]);

    const cat = useCategory(props.category);

    const day = (n: number | null): React.ReactNode => {
        const actualDateStart = new Date(firstDate.getFullYear(), firstDate.getMonth(), n ?? 1);
        const actualDateEnd = new Date(firstDate.getFullYear(), firstDate.getMonth(), n ?? 1, 23, 59, 59);

        const numAtDay = cat.events.filter(ev => {
            return ev.date >= actualDateStart.getTime() && ev.date <= actualDateEnd.getTime();
        }).length;

        return (
            <div className={css(styles.day)}>
            <Badge color="secondary" overlap="circular" badgeContent={n == null ? 0 : numAtDay}>
                <IconButton>
                    {n ?? ' '}
                </IconButton>
            </Badge>
            </div>
        );
    };

    const weeks = useMemo(() => {
        const weeks = []
        
        let currentDate: Date | null = new Date(firstDate);

        for (let j = 0; j < 5; j++) {
            const week = [];

            if (currentDate != null) {
                for (let i = 0; i < 7; i++) {
                    if (currentDate == null || i < usa2world(currentDate.getDay())) {
                        week.push(null);
                    } else {                    
                        week.push(currentDate.getDate());
                        currentDate.setDate(currentDate.getDate() + 1);

                        if (currentDate.getMonth() !== firstDate.getMonth()) {
                            currentDate = null;
                        }
                    }
                }
            }

            weeks.push(week);
        }

        return weeks;
    }, [firstDate]);

    return (
        <div className={css(styles.month)}>
            <div className={css(styles.monthName)}>
                <Typography>
                    {firstDate.toLocaleDateString('en-GB', {month: 'long'})}
                </Typography>
            </div>
            {weeks.map(wk => (
                <div className={css(styles.row)}>
                    {wk.map(day)}
                </div>
            ))}
        </div>
    )
}


type Props = {
    category: Category,
};

export default function Calendar(props: Props) {
    const [date, setDate] = useState(new Date());

    const next = () => {
        date.setMonth(date.getMonth() + 1);
        setDate(new Date(date));
    };

    const prev = () => {
        date.setMonth(date.getMonth() - 1);
        setDate(new Date(date));
    };

    const cat = useCategory(props.category);

    return (
        <div className={css(styles.container)}>
            <Month month={date.getMonth()} year={date.getFullYear()} category={cat} />
            <ButtonGroup variant='outlined'>
                <Button onClick={prev}><UndoIcon /></Button>
                <Button onClick={next}><RedoIcon /></Button>
            </ButtonGroup>
        </div>
    );
}

const styles = StyleSheet.create({
    container: {
        // height: 200,
        width: '100%',
        marginTop: 16,
        marginBottom: 16,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflow: 'scroll',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
    },
    day: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 36,
    },
    month: {
        display: 'flex',
        flexDirection: 'column',
    },
    monthName: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        width: '100%',
        paddingLeft: 16,
    },
});
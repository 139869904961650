import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField } from "@mui/material";
import { Categories, Category, sync, useCategory } from "../data/Data";
import EditIcon from '@mui/icons-material/Edit';
import { useState } from "react";

type Props = {
    category: Category,
};

export default function EditDialog(props: Props) {
    const cat = useCategory(props.category);

    const [open, setOpen] = useState(false);
    const [name, setName] = useState(cat.name);

    const onCreate = () => {
        if (name.trim() !== '') {
            cat.name = name;
            sync();
            setOpen(false);
        }
    };
    
    const onDelete = () => {
        const index = Categories.findIndex(c => c.name === cat.name);
        if (index !== -1) {
            Categories.splice(index, 1);
            sync();
        }
    };

    return (
        <>
            <IconButton onClick={() => setOpen(true)}><EditIcon/></IconButton>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>
                    Edit Category "{cat.name}"
                </DialogTitle>
                <DialogContent>
                    <div style={{height: 5}} />
                    <TextField
                            autoFocus
                            id="name"
                            label="Change Name"
                            fullWidth
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>Cancel</Button>
                    <Button onClick={onDelete} color="warning">Delete</Button>
                    <Button onClick={onCreate}>Rename</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}